<template>
  <div>
    <ManageAnnouncement />
  </div>
</template>
<script>
import ManageAnnouncement from "@/components/Announcement/ManageAnnouncement";
export default {
  components: {
    ManageAnnouncement,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>